import { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GalleryListItem, GalleryModel } from '@model/gallery.model';
import { GalleryParagraphModel } from '@organisms/GalleryParagraph/GalleryParagraph.model';
import { useColors } from '@hooks/useColors';
import { useCarousel } from '@hooks/useCarousel';
import { parseHTML } from '@utils/functions/parseHTML';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';

// Can't work with Embla
// const Box = dynamic(() => import('@mui/material/Box'));

const Grid = dynamic(() => import('@mui/material/Grid'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const ImageCaption = dynamic(() => import('@atoms/ImageCaption'));
const GalleryItem = dynamic(() => import('@organisms/GalleryParagraph/GalleryItem/GalleryItem'));
const CarouselButtons = dynamic(() => import('@molecules/CarouselActions/CarouselButtons'));
const FullScreenImageGallery = dynamic(() => import('@molecules/FullscreenGalleryImage/FullScreenImageGallery'));

const styles = {
  image: {
    firstColumn: {
      lg: 2,
    },
    secondColumn: {
      lg: 8,
    },
  },
  video: {
    firstColumn: {
      lg: 1,
    },
    secondColumn: {
      lg: 10,
    },
  },
};

const GalleryParagraph = ({ fieldParagraph, type = 'image' }: GalleryParagraphModel) => {
  // const {
  //   mappedData: { data, isLoaded },
  // } = useMapper<any, GalleryModel>({
  //   mockDataUrl: fieldParagraph.mockDataUrl || '/data/video-gallery.json',
  //   backendData: fieldParagraph,
  //   realDataMapper: (setData, backendData) => {
  //     if (type === 'video') {
  //       setData({
  //         title: backendData.field_title,
  //         subtitle: backendData.field_description?.value,
  //         colorVariant: backendData.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
  //         overline: '',
  //         items: backendData.field_medias.map(
  //           (media) =>
  //           ({
  //             videoUrl: media.field_media_oembed_video,
  //           } as GalleryListItem)
  //         ),
  //       });

  //       return;
  //     }
  //     setData({
  //       title: backendData.field_title || '',
  //       subtitle: backendData.field_description?.value || '',
  //       colorVariant: backendData.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
  //       overline: '',
  //       items: backendData.field_medias.map((media) => ({
  //         image: {
  //           imageAlt: '',
  //           imageUrl: absoluteUrl(media?.field_media_image?.uri?.url),
  //         },
  //       })),
  //     });
  //   },
  // });

  const colorVariant: string | 'dark' | 'light' =
    `${fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class}` || 'light';

  const data = useMemo(() => {
    if (type === 'video') {
      const newData: GalleryModel = {
        anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
        title: fieldParagraph.field_title,
        subtitle: fieldParagraph.field_description?.value,
        colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
        overline: '',
        items: fieldParagraph.field_medias.map(
          (media) =>
            ({
              videoUrl: media.field_media_oembed_video,
              title: media.name,
            } as GalleryListItem)
        ),
      };
      return newData;
    } else {
      const newData: GalleryModel = {
        anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
        title: fieldParagraph.field_title || '',
        subtitle: fieldParagraph.field_description?.value || '',
        colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
        overline: '',
        items: fieldParagraph.field_medias.map((media) => ({
          image: {
            imageAlt: media?.field_media_image?.resourceIdObjMeta?.alt || '',
            imageUrl: absoluteUrl(media?.field_media_image?.uri?.url),
          },
        })),
      };
      return newData;
    }
  }, [fieldParagraph]);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const { backgroundColor, textColor } = useColors(data?.colorVariant);

  const { emblaRef, emblaApi, selectedIndex, scrollSnaps } = useCarousel({
    slidesToScroll: 1,
    containScroll: 'trimSnaps',
    draggable: data?.items.length > 1,
  });

  const slidesNotInView = useMemo(() => {
    if (!emblaApi) return [];

    return emblaApi.slidesNotInView(true);
  }, [emblaApi, selectedIndex]);

  const isVerticalVariant =
    !!fieldParagraph?.behavior_settings?.style_options?.gallery_styles?.css_class?.['vertical-slides'];

  if (!data) return null;

  return (
    <>
      <GridContainer
        anchor-data={transformTitleId(data.anchorData || '')}
        sx={{ position: 'relative', overflow: 'hidden', py: 80, backgroundColor }}
      >
        <Row>
          <Grid item xs={0} lg={styles[type].firstColumn.lg} />
          <Grid item xs={12} lg={styles[type].secondColumn.lg} sx={{ textAlign: 'center' }}>
            {data.overline && (
              <Typography color={textColor} variant="overlineMono">
                {data.overline}
              </Typography>
            )}

            {data.title && (
              <Typography mt={16} color={textColor} variant="h4">
                {data.title}
              </Typography>
            )}

            {data.subtitle && (
              <Typography component="div" mt={24} color={textColor} variant="subtitle">
                {parseHTML(data.subtitle)}
              </Typography>
            )}

            <Box mt={72} position="relative" ref={emblaRef}>
              <Row position="relative" flexWrap="nowrap">
                {data.items.map((galleryItem, index) => (
                  <Grid key={`${galleryItem?.videoUrl}_${index}`} item xs={12} flexShrink={0}>
                    <Typography mb={24} color={textColor} variant="h6">
                      {galleryItem.title}
                    </Typography>

                    <GalleryItem
                      index={index}
                      handleImageClick={(index) => {
                        setIsFullscreen(true);
                        setImageIndex(index);
                      }}
                      slidesNotInView={slidesNotInView}
                      image={galleryItem.image}
                      videoUrl={galleryItem.videoUrl}
                      {...(isVerticalVariant && { variant: 'vertical' })}
                    />
                  </Grid>
                ))}
              </Row>
            </Box>

            {data?.items?.at(selectedIndex)?.image ? (
              <ImageCaption color={colorVariant === 'dark' ? '#fff' : '#000'}>
                {data?.items?.at(selectedIndex)?.image?.imageAlt}
              </ImageCaption>
            ) : (
              <></>
            )}
          </Grid>
        </Row>

        {data?.items?.length > 1 && (
          <CarouselButtons
            sx={{
              top: theme.spacing(462),
              right: theme.spacing(32),
            }}
            emblaApi={emblaApi}
            scrollSnapsLength={scrollSnaps.length}
            colorVariant={data.colorVariant}
            selectedIndex={selectedIndex}
          />
        )}
      </GridContainer>

      {isFullscreen && (
        <FullScreenImageGallery
          index={imageIndex}
          handleClose={() => setIsFullscreen(false)}
          colorVariant={data.colorVariant}
          items={data.items}
        />
      )}
    </>
  );
};

export default GalleryParagraph;
